export const latlng2yx = (
  lat: number,
  lng: number
): { y: number; x: number } => {
  let y = lat,
    x = 0
  if (lng > -30 && lng < 150) {
    x = -(150 - lng)
  } else if (lng > 150) {
    x = lng - 150
  } else if (lng < -30) {
    x = 30 + (180 + lng)
  }
  return { y, x }
}

export const setPopupOffset = (y: number, x: number) => {
  let offsetX = 0,
    offsetY = 5
  if (x > 90) {
    offsetX = -130
  }
  if (x < -90) {
    offsetX = 130
  }
  if (y > 30) {
    offsetY = 140
  }

  return {
    offsetX,
    offsetY,
  }
}
