import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import SEO from '../components/SEO'
import '../styles/newIndex.less'
import { Tooltip } from 'antd'
// import {ModalForomWrap}
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Map } from '../components/newMap'
import { ConsultationFormWrap } from '../components/form-biz'
import { latlng2yx } from '../components/newMap/utils'
import { Banner } from '../pages-en'
import { graphql } from 'gatsby'
const bannerSetting = {
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  className: 'center',
  autoplaySpeed: 5000,
  dots: false,
  arrows: false,
  rows: 1,
  slidesPerRow: 1,
  autoplay: true,
}
const Button = ({
  // text,
  type,
  onClick,
  width = 'auto',
  children,
}: {
  // text: string
  children: React.ReactChild
  type?: string
  width?: string
  onClick?: () => void
}) => (
  <button
    className={`consoleStyle ${type}`}
    onClick={() => {
      if (onClick) onClick()
    }}
    style={{ width }}
  >
    {children}
  </button>
)
const onClickJumpBtn = () => {
  const link = 'https://console.zenlayer.com/auth/signup/input'
  window.open(link)
}

const TopBanner = () => (
  <div className="topBanner">
    <div className="topBanner-bg">
      <div className="headerContainer">
        <Header logo="/logo-blue.svg" />
      </div>
      <div className="content">
        <h1>
          Hyperconnected edge cloud
          <br /> for interactive applications.
        </h1>
        <div className="subtitle">Reliable. Fast. Easy.</div>
        <div className="description">
          <div>
            Quickly deploy your applications to <b>110+</b> cities in <b>50+</b>
            countries.
          </div>
          <div>
            Interconnect your workloads with an ultra-fast,
            <b>private global network.</b>
          </div>
          <div>
            Give your users a smooth, no-lag{' '}
            <b>experience, anytime, anywhere.</b>
          </div>
        </div>
        <div className="btnRow">
          <Button type="primary" onClick={onClickJumpBtn}>
            Try it now
          </Button>
          <ConsultationFormWrap source="index">
            {({ setOpen }) => (
              <Button onClick={() => setOpen(true)}>Talk to expert</Button>
            )}
          </ConsultationFormWrap>
        </div>
      </div>
    </div>
  </div>
)
const B1 = () => (
  <div className="b1">
    <div className="title">
      Trusted by the world’s leading interactive brands
    </div>
    {[0, 1, 2, 3, 4, 5].map((key) => (
      <img src={`/newIndex/b1-${key}.svg`} alt={`b1-${key}`} />
    ))}
  </div>
)
const B2 = () => {
  const products = [
    {
      name: 'Bare metal instance',
      description: [
        'Dedicated, high-performance compute',
        'Available in 55 cities across 35+ countries',
      ],
    },
    {
      name: 'Virtual instance',
      description: [
        'Elastic, low-cost compute',
        'Available in 20+ edge locations across Asia, Central/South America, Europe, Middle East, and Africa.',
      ],
    },
  ]
  const [active, setActive] = useState<string>()

  return (
    <div className="b2">
      <div className="title">
        Instantly deploy applications
        <br />
        <b>close to your users</b>
      </div>
      <div className="description">
        Choose from 280+ edge PoPs in the fastest growing regions of the world:
        China, India, Southeast Asia, South America, Russia, Africa, and more.
      </div>
      <div className="content">
        <div className="left">
          {products.map((p, i) => (
            <div className="productBox">
              <div className="name">
                <img src={`/newIndex/b2-icon-${i}.svg`} alt={p.name} />
                {p.name}
              </div>
              <ul>
                {p.description.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
              <div
                className="learnMore"
                onClick={() => {
                  const link =
                    'https://www.zenlayer.com/products/cloud-networking/'
                  window.open(link)
                }}
              >
                Learn more
              </div>
            </div>
          ))}
          <Button type="primary" width="100%" onClick={onClickJumpBtn}>
            Deploy now
          </Button>
        </div>
        <div className="right">
          <div className="b2-map">
            <Map height="444px" mini={true}>
              {({ map }) => (
                <div className="toolbox">
                  <div className="title">Your users are closer to:</div>
                  {[
                    'Americas',
                    'Asia Pacific',
                    'Europe Middle East and Africa',
                  ].map((name) => (
                    <div
                      className={`item ${name === active ? 'active' : ''}`}
                      onClick={() => {
                        setActive(name)
                        const coord: any = {
                          Americas: [50, -120],
                          'Asia Pacific': [40, 100],
                          'Europe Middle East and Africa': [50, 10],
                        }[name]
                        const { x, y } = latlng2yx(coord[0], coord[1])
                        map.flyTo([y, x])
                      }}
                    >
                      {name}
                    </div>
                  ))}
                  <Slider {...bannerSetting}>
                    {new Array(11).fill('').map((_, idx) => (
                      <div>
                        <div className="human">
                          <img
                            src={`/newIndex/human-${idx + 1}.jpg`}
                            alt={`banner-${idx}`}
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </Map>
          </div>
        </div>
      </div>
    </div>
  )
}
const B3 = () => {
  return (
    <div className="b3">
      <div className="content">
        <div className="left">
          <img
            src="/newIndex/b3-pic.svg"
            alt="Zenlayer Private global network"
          />
        </div>
        <div className="right">
          <div className="title">
            Interconnect your workloads on <br />a private global network
            <b> in minutes</b>
          </div>
          <div className="description">
            Our compute nodes are interconnected via our private backbone and
            use optimized local networks to reach your users with minimal
            latency.
          </div>
          <div className="productBox">
            <div className="name">
              <img src={`/newIndex/b3-icon.svg`} alt="Cloud Networking" />
              Cloud networking
            </div>
            <ul>
              {[
                'Easily connect services between clouds, data centers, and SaaS.',
                'Create a seamless multi-region network across six continents.',
                'Ensure compliance everywhere, including China.',
              ].map((i) => (
                <li>{i}</li>
              ))}
            </ul>
            <div
              className="learnMore"
              onClick={() => {
                const link =
                  'https://www.zenlayer.com/products/cloud-networking/'
                window.open(link)
              }}
            >
              Learn more
            </div>
          </div>
          <Button type="primary" width="100%" onClick={onClickJumpBtn}>
            Deploy now
          </Button>
        </div>
      </div>
    </div>
  )
}
const B4 = () => {
  const results = [
    {
      name: 'Live Streaming',
      tags: ['BMC', 'VM'],
      icon: 'zego',
      stats: [
        { name: 'Decrease latency', number: '25%', type: 'speed' },
        {
          name: 'Increased user satisfaction',
          number: '35%',
          type: 'satisfaction',
        },
        { name: 'Lower costs', number: '30%', type: 'money' },
      ],
    },
    {
      name: 'Online Education',
      tags: ['Online Education'],
      icon: 'talk',
      stats: [
        { name: 'Decrease latency', number: '5%', type: 'speed' },
        { name: 'Increased in coverage', number: '40%', type: 'coverage' },
        {
          name: 'Increased user satisfaction',
          number: '35%',
          type: 'satisfaction',
        },
      ],
    },
    {
      name: 'Gaming platform',
      tags: ['Gaming'],
      stats: [
        { name: 'Servers', number: '1100', type: 'speed' },
        { name: 'Countries', number: '10', type: 'country' },
        { name: 'Continents', number: '5', type: 'continent' },
      ],
    },
  ]
  return (
    <div className="b4">
      <div className="title">Real results</div>
      <div className="content">
        {results.map(({ name, tags, stats, icon }, i) => (
          <div className="result">
            <div
              className="top"
              style={{ backgroundImage: `url("/newIndex/b4-bg-${i}.png")` }}
            >
              {icon && <img src={`/newIndex/${icon}.svg`} alt={name} />}
            </div>
            <div className="bottom">
              <div className="title">{name}</div>
              {tags.map((tag) => (
                <div className="tag">{tag}</div>
              ))}
              <div className="statContent">
                {stats.map(({ name, number, type }) => (
                  <div className="item">
                    <div>
                      <img src={`/newIndex/${type}.svg`} alt={number} />
                    </div>
                    <div className="number">{number}</div>
                    <div className="name">{name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const B5 = () => {
  const stats = [
    {
      name: 'Edge nodes',
      data: '280+',
    },
    {
      name: 'Network capacity',
      data: '50+ Tbps',
    },
    {
      name: 'Peers',
      data: '4,300+',
    },
  ]
  return (
    <div className="b5">
      <div className="title">
        <b>#1</b> edge cloud provider in emerging markets
      </div>
      <div className="description">
        Reach 85% of internet users in under 25 milliseconds
      </div>
      <div className="content">
        <div className="b5-map">
          <Map domId="map2" />
        </div>

        <div className="stats">
          <div className="title">Resource coverage</div>
          <div>
            {stats.map(({ name, data }) => (
              <div className="item">
                <div className="data">{data}</div>
                <div className="name">{name}</div>
              </div>
            ))}
          </div>
          <div className="btnRow">
            <Button width="100%" onClick={onClickJumpBtn}>
              Deploy now
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
const B6 = () => {
  const data = [
    'Amazon Web Services',
    'Microsoft Azure',
    'Google Cloud Platform',
    'Alibaba Cloud',
    'Fortinet',
    'Megaport',
    'Cloudflare',
    'Seagate',
    'Intel',
    'Equinix',
    'Telkom Indonesia',
    'Viettel',
  ]
  return (
    <div className="b6">
      <div className="title">Trusted by partners globally</div>
      <div className="content-bg">
        <div className="content">
          {data.map((d) => (
            <div key={d} className="item">
              <Tooltip title={d} placement="right">
                <img src={`/newIndex/${d}.svg`} alt={d}></img>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default function Home({ data }) {
  const { allStrapiBanners } = data
  const banners = allStrapiBanners.nodes.filter((banner) => !!banner.link)
  const hasBanner = banners.length > 0
  return (
    <div className="newIndex">
      <SEO
        title="Zenlayer | Improve digital experiences. Instantly."
        description=""
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <B1 />
      <B2 />
      <B3 />
      {hasBanner && (
        <div style={{ background: '#eff2f5', paddingTop: '48px' }}>
          <Banner banners={banners} />
        </div>
      )}
      <B4 />

      <B5 />
      <B6 />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiBanners(filter: { displayed_in: { eq: "index" } }) {
      nodes {
        pic {
          localFile {
            publicURL
          }
        }
        pic_mobile {
          localFile {
            publicURL
          }
        }
        link
      }
    }
  }
`
