import React, { useEffect, useMemo, useRef, useState } from 'react'
import 'leaflet/dist/leaflet.css'
import './index.less'
const L = typeof window !== 'undefined' ? require('leaflet') : () => null
import { useSize, useUpdateEffect, useThrottle, useLatest } from 'ahooks'
import { latlng2yx, setPopupOffset } from './utils'
import { cities } from '../map/bmcMapData'
// const cities = [
//   {
//     region: 'Americas',
//     city: 'Los Angeles',
//     id: '622b1ae7-bcf4-4c15-883b-d87de38f02b5-0x1',
//     lat: 34.0562978,
//     lng: -118.2392098,
//   },
//   {
//     region: 'Americas',
//     city: 'Washington D.C.',
//     id: '217f4f9c-0ebb-4231-912b-77273b8e5457-0x2',
//     lat: 38.96053067,
//     lng: -77.3802455,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Frankfurt',
//     id: 'a32f4687-84d5-44f0-ae23-b00cc1b3371f-0x3',
//     lat: 50.1086355,
//     lng: 8.6897845,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Singapore',
//     id: 'c82f47d3-4f44-4a6a-bf0f-39738b964196-0x4',
//     lat: 1.346063075,
//     lng: 103.7398523,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Tokyo',
//     id: '557c0774-4d49-46da-bb1f-5f4069b68fbb-0x5',
//     lat: 35.64314775,
//     lng: 139.7796599,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Beijing',
//     id: '754dbd11-35e3-43c4-a68f-408fa7a9390d-0x6',
//     lat: 39.802067,
//     lng: 116.481986,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Shanghai',
//     id: 'b4c65a54-92d8-4032-8708-ee4840646921-0x7',
//     lat: 31.339748,
//     lng: 121.437225,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Guangzhou',
//     id: 'd3832505-d665-418c-a04a-2b65365a70aa-0x8',
//     lat: 23.1440285,
//     lng: 113.4864655,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Jakarta',
//     id: '09592108-7031-41db-8b9b-2aee2300171b-0x9',
//     lat: -6.263640714,
//     lng: 106.7119349,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Hong Kong',
//     id: 'b128368d-2928-42fa-b05b-d7fcb1993175-0xa',
//     lat: 22.336301,
//     lng: 114.213294,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Amsterdam',
//     id: '283cb640-3c74-4c59-8917-ec03928fec76-0xb',
//     lat: 52.3162095,
//     lng: 4.94368875,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Seoul',
//     id: '71cb89ae-4722-4c96-9097-9e5bf4bc963a-0xc',
//     lat: 37.3835566,
//     lng: 127.0885664,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Moscow',
//     id: 'c506c670-a7f3-4d33-9e2f-234631dde187-0xd',
//     lat: 55.87064333,
//     lng: 37.55597867,
//   },
//   {
//     region: 'Americas',
//     city: 'San José',
//     id: '55d18518-8d76-4e21-bbe7-964dde4fa45b-0xe',
//     lat: 37.378419,
//     lng: -121.955008,
//   },
//   {
//     region: 'Americas',
//     city: 'New York',
//     id: '1ef0057c-acb9-4a2c-ab63-39a50c2e3c28-0xf',
//     lat: 40.71911885,
//     lng: -74.00710066,
//   },
//   {
//     region: 'Americas',
//     city: 'Miami',
//     id: '042e3ac9-2b5a-41e0-9c63-50b16dcc6e82-0x10',
//     lat: 25.797933,
//     lng: -80.230116,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Mumbai',
//     id: '1b8be798-eb85-4578-95e7-ceced4e9d113-0x11',
//     lat: 19.15789771,
//     lng: 73.00514614,
//   },
//   {
//     region: 'Americas',
//     city: 'São Paulo',
//     id: '98bbb026-b3fe-457c-ba30-32ace6daff12-0x12',
//     lat: -23.47930232,
//     lng: -46.12161169,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Taipei',
//     id: 'bf7cb23c-49e3-49ce-b825-489039ee3548-0x13',
//     lat: 25.0484551,
//     lng: 121.561629,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'London',
//     id: 'daf193b2-c828-44cf-a86b-a3732df2617a-0x14',
//     lat: 51.5099341,
//     lng: -0.32246655,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Bangkok',
//     id: 'a0d9791a-8caa-41a7-8af1-d71d9df093f2-0x15',
//     lat: 13.83167833,
//     lng: 100.5873727,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Manila',
//     id: '966b901d-edf5-401e-8e78-620fb6d61b0e-0x16',
//     lat: 14.53927157,
//     lng: 121.0214147,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Kuala Lumpur',
//     id: 'cbbcd4b6-61df-49af-805c-fe9165699ae6-0x17',
//     lat: 3.150052,
//     lng: 101.706431,
//   },
//   {
//     region: 'Americas',
//     city: 'Dallas',
//     id: '32d8ce4e-e538-41e4-a32f-0e233f9ac20a-0x18',
//     lat: 32.86128213,
//     lng: -96.77021157,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Ho Chi Minh City',
//     id: 'bff2d2b7-1ca9-4d05-b24b-9cb70b1149a1-0x19',
//     lat: 10.75856,
//     lng: 106.7422677,
//   },
//   {
//     region: 'Americas',
//     city: 'Seattle',
//     id: '12fd58af-bbba-4248-be91-ae3b5470a17a-0x1a',
//     lat: 47.493194,
//     lng: -122.288909,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'New Delhi',
//     id: 'ff5df109-074c-4ebf-a277-bc63272ed8a6-0x1b',
//     lat: 28.53022733,
//     lng: 77.27222667,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Chennai',
//     id: '9d000f0d-4cfb-424f-9004-ca61dee1a6cf-0x1c',
//     lat: 12.95010333,
//     lng: 80.143312,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Johannesburg',
//     id: '5f8b6a09-6553-416e-9416-95c41bfe8366-0x1d',
//     lat: -26.137414,
//     lng: 28.197624,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Dubai',
//     id: '68e02c05-fbbd-476c-9007-7e1238484c02-0x1e',
//     lat: 25.029016,
//     lng: 55.186429,
//   },
//   {
//     region: 'Americas',
//     city: 'Chicago',
//     id: '3d57791a-1168-4226-b6c7-0bc128908845-0x1f',
//     lat: 42.001361,
//     lng: -87.954778,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Bangalore',
//     id: '1076a840-516b-4008-baac-e6a668f9154f-0x20',
//     lat: 12.97795,
//     lng: 77.710233,
//   },
//   {
//     region: 'Americas',
//     city: 'Los Angeles - China Optimized',
//     id: 'c63d8bd3-67d3-4261-bd6c-5d9983562f9d-0x21',
//     lat: 34.05163767,
//     lng: -118.2497957,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Hong Kong - China Optimized',
//     id: 'd5edb942-3d98-4da1-8766-cd4272cc070d-0x22',
//     lat: 22.39836,
//     lng: 114.190563,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Lagos',
//     id: '65a677ad-6c14-418c-8247-198f8fa5dc02-0x23',
//     lat: 6.450773,
//     lng: 3.592092,
//   },
//   {
//     region: 'Americas',
//     city: 'Atlanta',
//     id: 'fccdb9ea-6841-4be4-9841-3dbf42316400-0x24',
//     lat: 33.761799,
//     lng: -84.391442,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Istanbul',
//     id: 'ff228b10-bbc4-4588-8de8-4b71eae6f965-0x25',
//     lat: 41.05616478,
//     lng: 29.05016072,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Yangon',
//     id: 'a6ea4634-c78a-445e-932a-7d02d53dab94-0x26',
//     lat: 16.812021,
//     lng: 96.134693,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Fujairah City',
//     id: '5113d758-7f7d-4077-bc10-4c85fa5251db-0x27',
//     lat: 25.149834,
//     lng: 56.35385,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Dhaka',
//     id: 'd871c5c2-a9f6-447f-a5ab-fea8aa8b5c24-0x28',
//     lat: 23.76474202,
//     lng: 90.41452169,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Marseille',
//     id: '40ec6011-6a7c-4299-b15c-6b4cfa92425b-0x29',
//     lat: 43.336735,
//     lng: 5.353049,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Paris',
//     id: '76068ddd-bc0e-435c-aeee-01f57a1133fd-0x2a',
//     lat: 48.9590606,
//     lng: 2.41567905,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Madrid',
//     id: '92af793e-f7ed-4dba-81e2-e37352278e44-0x2b',
//     lat: 40.43993,
//     lng: -3.621013,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Karachi',
//     id: '5238257d-3610-4725-abe5-acc4366186ee-0x2c',
//     lat: 24.84066285,
//     lng: 67.0492958,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Agra',
//     id: '4be28cc0-43b2-40dd-bf82-a8df5f1d9e38-0x2d',
//     lat: 27.1618309,
//     lng: 77.9707261,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Hyderabad',
//     id: 'a5c55d3b-0abf-40ab-a052-71a187efbeff-0x2e',
//     lat: 17.442,
//     lng: 78.377562,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Nagpur',
//     id: 'b845283a-1b2a-468c-8ecd-a736dc5f2996-0x2f',
//     lat: 21.0532,
//     lng: 79.025,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Bhopal',
//     id: '49391ede-fbf1-4e15-bf3c-dc5f89e28b5b-0x30',
//     lat: 23.1715,
//     lng: 77.2015,
//   },
//   {
//     region: 'Americas',
//     city: 'Mexico City',
//     id: 'aa8c45b0-2538-4f8e-9a1e-334df1e8bf6c-0x31',
//     lat: 20.18226775,
//     lng: -99.7870273,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Kolkata',
//     id: 'e5ac36a3-6bb6-4db1-b178-f793e605eeab-0x32',
//     lat: 22.588635,
//     lng: 88.39308,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Pathum Thani',
//     id: '94ac3e82-bcf0-455c-9333-06965607f037-0x34',
//     lat: 13.5445,
//     lng: 100.3624,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Vientiane',
//     id: '9f3cbf57-1d43-44ff-9f2e-6fe3f62976ad-0x35',
//     lat: 17.5918,
//     lng: 102.3348,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Tokyo - China Optimized',
//     id: 'dbe28c0f-7feb-4229-b5ea-ec41b8c1298f-0x36',
//     lat: 35.6638375,
//     lng: 139.8115868,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Islamabad',
//     id: '53a1316e-f202-4a7a-96a4-87706075a0fa-0x37',
//     lat: 33.6848889,
//     lng: 73.0757028,
//   },
//   {
//     region: 'Americas',
//     city: 'Rio de Janeiro',
//     id: '39f328bb-59b3-4897-9011-5ae7923cecf2-0x38',
//     lat: -22.868957,
//     lng: -43.272929,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Hanoi',
//     id: 'fe41326e-dddf-4b6d-a30e-9ee3f675bb01-0x39',
//     lat: 21.007838,
//     lng: 105.5288935,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Milan',
//     id: '95a32255-1fda-450b-888c-729792621462-0x3b',
//     lat: 45.448729,
//     lng: 9.147479,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Cairo',
//     id: 'ebd74e49-aa2e-4a1b-91fd-022121cda521-0x44',
//     lat: 30.099009,
//     lng: 31.173065,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Sydney',
//     id: '5fc57f4b-dc2a-488e-b59e-0a064fceb8bd-0x45',
//     lat: -33.91844164,
//     lng: 151.1890609,
//   },
//   {
//     region: 'Americas',
//     city: 'Fortaleza',
//     id: 'e594f84c-a97c-48e4-8f91-2dec07914742-0x46',
//     lat: -3.808412,
//     lng: -38.5273851,
//   },
//   {
//     region: 'Asia Pacific',
//     city: 'Osaka',
//     id: '094e1874-4982-45e6-b2ad-7ad43110eb63-0x4d',
//     lat: 34.6932505,
//     lng: 135.528708,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Warsaw',
//     id: '6624e677-0981-4d49-b5b9-3d9d95d1cd48-0x4e',
//     lat: 52.190552,
//     lng: 20.921186,
//   },
//   {
//     region: 'Europe Middle East and Africa',
//     city: 'Stockholm',
//     id: '009ae49c-b25d-4a3b-8391-306b7ac14efe-0x4f',
//     lat: 59.4224745,
//     lng: 17.9158821,
//   },
//   {
//     region: 'Americas',
//     city: 'Bogotá',
//     id: '57dc1fa1-e3eb-4216-ab06-ad73911d978f-0x50',
//     lat: 4.451517,
//     lng: -74.82229,
//   },
//   {
//     region: 'Americas',
//     city: 'Buenos aires',
//     id: '866e07fb-db78-4e14-840d-e8ce622c8763-0x51',
//     lat: -34.5900558,
//     lng: -58.4694041,
//   },
//   {
//     region: 'Americas',
//     city: 'Santiago',
//     id: '17af7101-48a1-414d-8017-e9df0bb238c6-0x52',
//     lat: -33.3365076,
//     lng: -70.7097286,
//   },
// ]

export interface Citys {
  lat: number
  lng: number
  city: string
}

interface Props {
  //     citys?: Citys[]
  domId?: string
  height?: string
  mini?: boolean
  children?: ({ map }: { map: any }) => React.ReactChild
}

export const Map: React.FC<Props> = ({
  domId = 'map',
  height,
  children,
  mini = false,
}) => {
  const LatestCitys = useLatest(cities)
  const mapBG = '/newIndex/map.svg'
  //   const history = useHistory()
  //   const [theme] = useTheme()
  //   const mapBG = useMemo(() => {
  //     if (theme === 'dark') {
  //       return MapBG_dark
  //     } else {
  //       return MapBG_light
  //     }
  //   }, [theme])

  const divRef = useRef<HTMLDivElement>(null)
  const size = useSize(divRef) || { width: 0, height: 0 }
  const throttledSize = useThrottle(size, { wait: 300 })

  const map = useRef<any>()
  //画点
  const [markers, setMarkers] = useState<any[]>([])
  const LatestMarkers = useLatest(markers)
  const handleMarkers = () => {
    LatestMarkers.current?.forEach((m) => {
      m.remove()
    })
    let currentMarkers: any[] = []
    if (map.current) {
      const zoom = map.current.getZoom()
      const scale = zoom - 0.3 > 1 ? zoom - 0.3 : 1
      LatestCitys.current?.forEach((n) => {
        const { x, y } = latlng2yx(n.lat, n.lng)
        const marker = L.marker([y, x], {
          icon: L.divIcon({
            className: ``,
            html: `
              <div style="transform: scale(${scale});" class="map-dot">
              </div>
            `,
          }),
        })
          .bindPopup(
            L.popup({
              className: 'new-map-tooltip',
              //       maxWidth: 270,
              //       minWidth: 270,
              //       autoPan: false,
              //       closeButton: false,
              //       className: 'popup-wrap',
              //       offset: L.point(
              //         setPopupOffset(y, x).offsetX, //如果弹窗大小改变需要去改一下这个方法，目前硬算的
              //         setPopupOffset(y, x).offsetY
              //       ),
            })
              //       .setLatLng([y, x])
              .setContent(() => {
                //       const bmcInfo = n.cityResourceInfo?.bmc
                //       const mhsInfo = n.cityResourceInfo?.mhs
                // <span class="link" data-link="/bmc/server/create?cityUuid=${
                //                       n.cityId
                //                     }">Add</span>
                return `<div class="popup-box">
                        <div class="cityName">${n.city}</div>
                      </div>`
              })
          )
          .on('mouseover', function (ev) {
            ev.target.openPopup()
          })
          .addTo(map.current as any)
        currentMarkers.push(marker)
      })
    }
    setMarkers(currentMarkers)
  }

  //new map
  useEffect(() => {
    const southWest = L.latLng(-90, -180) //西南
    const northEast = L.latLng(90, 180) //东北
    const maxBounds = L.latLngBounds(southWest, northEast)

    const mapOpts = {
      crs: L.CRS.Simple,
      zoomSnap: 0.01,
      maxBounds, //边界
      maxBoundsViscosity: 1.0, //防止用户拖出边界
    }
    if (!size.height || !size.width || !mapBG) {
      return
    }
    if (map.current) {
      map.current?.remove()
    }
    map.current = L.map(
      domId,
      mini ? { ...mapOpts, scrollWheelZoom: false } : mapOpts
    )
    var image = L.imageOverlay(mapBG, maxBounds, {}).addTo(map.current)
    map.current.fitBounds(image.getBounds())
    if (mini) {
      const { x, y } = latlng2yx(50, 10)
      map.current.setView([y, x], 2.5)
      handleMarkers()
    } else {
      map.current.setMinZoom(map.current?.getZoom())
      map.current.setMaxZoom(map.current?.getZoom() + 1)
      map.current.on('zoom', (e) => {
        handleMarkers()
      })
      handleMarkers()
    }
  }, [JSON.stringify(throttledSize)])

  useUpdateEffect(() => {
    handleMarkers()
  }, [cities])

  //   useEffect(() => {
  //     function handleLink(e: MouseEvent) {
  //       const { dataset = {} } = (e.target || {}) as any
  //       if (dataset.link) {
  //         history.push(dataset.link)
  //       }
  //     }
  //     divRef.current?.addEventListener('click', handleLink)
  //     return () => {
  //       divRef.current?.removeEventListener('click', handleLink)
  //     }
  //   }, [])

  return (
    <div style={{ position: 'relative' }}>
      {children && children({ map: map.current })}
      <div
        id={domId}
        ref={divRef}
        style={{ height: height || size.width / 2 }}
      />
    </div>
  )
}
